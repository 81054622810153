import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import css from "./HowItWorks.module.css"

/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/
const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "800px",
    margin: "auto",
    paddingRight: "1em",
    paddingLeft: "1em",
    [theme.breakpoints.down("md")]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "400px",
    },
  },
}))

export function HowItWorks() {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <div style={{ paddingTop: "5em", paddingBottom: "2em", width: "100%", textAlign: "center" }}>
        <Typography variant="h5" component="h3">
          FAQ
        </Typography>
      </div>
      <div className={css.block}>
        <Typography className={css.head}>How does it works?</Typography>
        <div className={css.text}>
          <Typography>
            We run the <a href="https://peterwang512.github.io/CNNDetection/">deep fake detection algorithm</a> inside
            your browser, the image is never sent to our servers. In fact, we do not have any server running :)
          </Typography>
          <Typography>
            This algorithm runs a neural network model trained to detect images generated by a{" "}
            <a href="https://en.wikipedia.org/wiki/Convolutional_neural_network">Convolutional Neural Network</a>, which
            is the state of the art deep fake generation technique.
          </Typography>
        </div>
      </div>
      <div className={css.block}>
        <Typography className={css.head}>Why is my deep fake image not properly classified as fake?</Typography>
        <div className={css.text}>
          <Typography>
            As of today, this state of the art algorithm only works well on original image data. Any transformation,
            like <a href="https://en.wikipedia.org/wiki/Image_scaling">scaling</a>,{" "}
            <a href="https://en.wikipedia.org/wiki/Digital_image_processing">filtering</a> or a making a screenshot of
            the original image will discard precious information from the image and is likely to fool the model.
          </Typography>
          <Typography>
            As progress is made on this field, researchers will continue to improve this detection algorithm and we will
            hopefully soon be able to detect deep fakes from transformed images as well.
          </Typography>
        </div>
      </div>
    </div>
  )
}
